import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Bars } from "react-loader-spinner";
import useState from "react-usestateref";
import Moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import bitcoinIcon from "../images/landing/bitcoin-icon.webp";
import elliotImage from "../images/landing/solana-icon.webp";
import ehhereum from "../images/landing/ehhereum-icon.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Footer from "./Footer.js";

const Deposit = () => {
  const friendOptions = [
    {
      key: "jenny",
      text: "Bitcoin BTC",
      value: "jenny",
      image: bitcoinIcon,
    },
    {
      key: "elliot",
      text: "Elliot Fu",
      value: "elliot",
      image: elliotImage,
    },
    {
      key: "stevie",
      text: "Stevie Feliciano",
      value: "stevie",
      image: ehhereum,
    },
  ];
  const [selectedOption, setSelectedOption] = useState(friendOptions[0]);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState();
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [newAddres, setnewAddres] = useState("");
  const [newAddresErr, setnewAddresErr] = useState(false);
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");

  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);

  const bankdetails = (e) => {
    console.log(e, "ubujbjbujbjbjbn");
    setnewAddres(e);
    // if (e.length > 24) {
    //   setnewAddresErr(false);
    // }
  };
  const withdrawAction = async (data) => {
    var obj = {
      withdraw_id: data,
    };
    var data = {
      apiUrl: apiService.confirmWithdraw,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      showSuccessToast(resp.message, {
        toastId: "3",
      });
      //window.location.href = "/transaction";
      navigate("/withdraw");
    } else {
      showErrorToast(resp.message, {
        toastId: "3",
      });
      navigate("/withdraw");
    }
  };

  const qry_search = useLocation().search;
  const confirmation = new URLSearchParams(qry_search).get("transaction");
  if (confirmation != "" && confirmation != null) {
    withdrawAction(confirmation);
  }

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [withAddressValidate, setwithAddress] = useState(false);
  const [tfaValidate, settfaValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [sitekycStatus, setsitekycStatus, sitekycStatusref] =
    useState("DeActive");
  const [kycStatus, setkycStatus] = useState(1);
  const [Fullname, Setfullname, Fullnameref] = useState();
  const [Image, setImage, Imageref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [newFullname, setnewFullname, newFullnameref] = useState("Tether");
  const [newImage, setnewImage, newImageref] = useState("");
  const [newNetworks, setnewNetworks, newNetworksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [tokenAddressName, setTokenAddressName] = useState("");
  const { amount, withAddress, tfa, withdraw_otp } = formValue;

  useEffect(() => {
    getKYCstatus();
    getAllcurrency();
    getwithdrawHistory(1);
    getAddress();
    getSiteSettingstatus();
    var status = localStorage.getItem("withdraw_status");
    if (status == null) {
      localStorage.setItem("withdraw_status", false);
    }
    if (status == "false") {
      setalertTab("show");
    } else {
      setalertTab("remove");
    }
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(e, "eeeeeeeee");
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const handleChangs = async (e) => {
    setnewAddres(e.target.value);
    formValue["withAddress"] = e.target.value;
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].withdrawStatus == "Active") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
            currencyName: data[i].currencyName,
            imgurl: data[i].Currency_image,
          };
          currArrayCrypto.push(obj);
        }
      }
      setallCurrency(currArrayCrypto);
      setcurrency(currArrayCrypto[0].label);
      Setfullname(currArrayCrypto[0].currencyName);
      setImage(currArrayCrypto[0].imgurl);
      setcointype(currArrayCrypto[0].coinType);
      setnewCurrency(currArrayCrypto[0].label);
      setnewFullname(currArrayCrypto[0].currencyName);
      setnewImage(currArrayCrypto[0].imgurl);
      setcurrentcurrency(currArrayCrypto[0]);

      if (
        currArrayCrypto[0].bep20token == "1" &&
        currArrayCrypto[0].erc20token == "2" &&
        currArrayCrypto[0].trc20token == "2"
      ) {
        setTokenAddressName("BNB");
      } else if (
        currArrayCrypto[0].bep20token == "2" &&
        currArrayCrypto[0].erc20token == "1" &&
        currArrayCrypto[0].trc20token == "2"
      ) {
        setTokenAddressName("ERC");
      } else if (
        currArrayCrypto[0].bep20token == "2" &&
        currArrayCrypto[0].erc20token == "2" &&
        currArrayCrypto[0].trc20token == "1"
      ) {
        setTokenAddressName("TRX");
      }

      if (currArrayCrypto[0].coinType == "1") {
        onSelect(currArrayCrypto[0]);
      }
    }
  };
  const onSelect1 = async (option) => {
    setnewAddresErr(true);
    Setaddnewadd1(false);
    console.log(option, "-=-=-=-=option-=-=-=");
    setnewCurrency(option.label);
    setnewFullname(option.currencyName);
    setnewImage(option.imgurl);

    if (
      option.bep20token == "1" &&
      option.erc20token == "2" &&
      option.trc20token == "2"
    ) {
      setTokenAddressName("BNB");
    } else if (
      option.bep20token == "2" &&
      option.erc20token == "1" &&
      option.trc20token == "2"
    ) {
      setTokenAddressName("ERC");
    } else if (
      option.bep20token == "2" &&
      option.erc20token == "2" &&
      option.trc20token == "1"
    ) {
      setTokenAddressName("TRX");
    }
  };
  const onSelect = async (option) => {
    formValue.withAddress = null;
    setwithdrawAddress("");
    setcurrAddres([]);
    console.log(currAddresref.current, "=-=currAddresref=--=-");
    setnet_default(null);
    setwithdrawAddress(null);
    setcur_network("");
    setcurrency(option.label);
    Setfullname(option.currencyName);
    setImage(option.imgurl);
    setcointype(option.coinType);
    setnet_current("");
    getAddress();
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (option.label == "USD") {
      showErrorToast("Fiat withdraw is not allowed by the site");
    } else {
      if (indexData != -1) {
        var currencydata = allCryptoref.current[indexData];
        setcurrentcurrency(currencydata);
        setcur_network([]);
        setnet_default("");
        var network_cur = {};
        var network_names = [];
        if (currencydata.currencyType == "2") {
          if (currencydata.erc20token == "1") {
            network_cur = {
              value: "erc20token",
              label: "ERC20",
            };
            network_names.push(network_cur);
          }
          if (currencydata.bep20token == "1") {
            network_cur = {
              value: "bep20token",
              label: "BEP20",
            };
            network_names.push(network_cur);
          }
          if (currencydata.trc20token == "1") {
            network_cur = {
              value: "trc20token",
              label: "TRC20",
            };
            network_names.push(network_cur);
          }

          if (currencydata.rptc20token == "1") {
            network_cur = {
              value: "rptc20token",
              label: "RPTC20",
            };
            network_names.push(network_cur);
          }
          setcur_network(network_names);
          // setnet_default(network_currencyref.current[0].label);
        }
        setwithdrawType(currencydata.coinType);
        // if (currencydata.coinType== "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.user_balance,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setview(true);
          setBalance(resp.data);
        } else {
        }
        // } else {
        // }
      }
    }
  };

  const nav_page = async (link) => {
    navigate(link);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    setSiteLoader(true);

    var getKYC = await getMethod(data);
    setSiteLoader(false);

    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
      setBankwire(getKYC.bankdatastatus);
    } else {
      setkycStatus(0);
    }
  };

  const onSelect_network = async (option) => {
    setNetworks(option.label);
    setnet_current(option.label);
  };
  const onSelect_address = async (option) => {
    console.log(option, "=-=-=-=option=-=-=-=-=option=-=-=-option=-=-=");
    setwithdrawAddress(option.label);
    formValue.withAddress = option.label;
  };

  const withdrawPreview = async () => {
    try {
      console.log(formValue, "=-=-=v=-formValue-=-formValue=-=-vformValue");
      formValue["withAddress"] = newAddres;
      console.log(withAddress, "withAddress");
      if (
        currentcurrency.currencySymbol == "USD" ||
        currentcurrency.currencySymbol == "INR"
      ) {
        if (bankwire == 1) {
          if (withdrawType == "2") {
            validateFiat_preview(formValue);
            if (formValue.amount != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showErrorToast(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showErrorToast(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      showSuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      showErrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showErrorToast("Insufficient Balance!");
                  setbuttonLoader(false);
                }
              } else {
                showErrorToast("Please give valid withdraw amount!");
                setbuttonLoader(false);
              }
            }
          } else {
            validate_preview(formValue);
            if (formValue.amount != "" && formValue.withAddress != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showErrorToast(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showErrorToast(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      showSuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      showErrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showErrorToast("Insufficient Balance");
                  setbuttonLoader(false);
                }
              } else {
                showErrorToast("Please give valid withdraw amount!");
              }
            }
          }
        } else {
          showErrorToast("Kindly update your Bank details");
          navigate("/bankdetails");
        }
      } else {
        if (withdrawType == "2") {
          validateFiat_preview(formValue);
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showErrorToast(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showErrorToast(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    showSuccessToast(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    showErrorToast(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                showErrorToast("Insufficient Balance!");
                setbuttonLoader(false);
              }
            } else {
              showErrorToast("Please give valid withdraw amount!");
              setbuttonLoader(false);
            }
          }
        } else {
          validate_preview(formValue);
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showErrorToast(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showErrorToast(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    showSuccessToast(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    showErrorToast(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                showErrorToast("Insufficient Balance");
                setbuttonLoader(false);
              }
            } else {
              showErrorToast("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };
  const withdrawSubmit = async () => {
    try {
      if (withdrawType == "2") {
        validateFiat_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (formValue.amount != "" && formValue.tfa != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showErrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showErrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showSuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showErrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showErrorToast("Please give valid withdraw amount!");
            }
          } else {
            showErrorToast("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showErrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showErrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showSuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showErrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showErrorToast("Please give valid withdraw amount!");
            }
          } else {
            showErrorToast("Please give all the fields !");
          }
        }
      } else {
        validate_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (
            formValue.amount != "" &&
            formValue.withAddress != "" &&
            formValue.tfa != ""
          ) {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showErrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showErrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showSuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showErrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showErrorToast("Please give valid withdraw amount!");
            }
          } else {
            showErrorToast("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showErrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showErrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showSuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showErrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showErrorToast("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };

  const [newCurrency, setnewCurrency, newCurrencyref] = useState("");
  const [newCurrencyErr, setnewCurrencyErr] = useState(false);
  const [newNetwork, setnewNetwork] = useState("");
  const [alertTab, setalertTab] = useState("hide");
  const [allAddress, setallAddress, allAddressref] = useState([]);
  const [currAddres, setcurrAddres, currAddresref] = useState([]);
  const [withdrawAddress, setwithdrawAddress, withdrawAddressref] = useState();
  const [addnewadd, Setaddnewadd, addnewaddref] = useState(false);
  const [addnewadd1, Setaddnewadd1, addnewaddref1] = useState(false);

  const getAddress = async () => {
    var data = {
      apiUrl: apiService.getAddress,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      setallAddress(resp.data);
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i];
        if (currencyref.current == element.currency) {
          var dropData = {
            value: element.currency,
            label: element.address,
          };
          data.push(dropData);
        }
        setcurrAddres(data);
      }
    } else {
      setallAddress([]);
    }
  };
  const Addaddress = async () => {
    console.log(
      newAddres,
      newAddresErr,
      newCurrencyref.current,
      "======================"
    );
    if (newAddres != "" && newCurrencyref.current != "") {
      Setaddnewadd(false);
      if (newAddres == "") {
        setnewAddresErr(true);
      } else if (newCurrency == "") {
        setnewCurrencyErr(true);
        setnewAddresErr(false);
      } else {
        setnewCurrencyErr(false);
        var obj = {
          Address: newAddres,
          currency: newCurrency,
          network: newNetwork,
          tokenAddressName: tokenAddressName,
        };
        var data = {
          apiUrl: apiService.Addaddress,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          showSuccessToast(resp.message);
          setnewAddres("");
          setnewCurrency("");
          setnewNetwork("");
          getAddress();
        } else {
          showErrorToast(resp.message);
        }
      }
    } else {
      console.log("fill the all field");
    }
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
     if (toastId) {
       toast.dismiss(toastId);
     }
     const newToastId = toast.success(message);
     setToastId(newToastId);
   };
 
   const showErrorToast = (message) => {
     if (toastId) {
       toast.dismiss(toastId);
     }
     const newToastId = toast.error(message);
     setToastId(newToastId);
   };
 
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast("Address copied");
  };

  const addnewaddress = async () => {
    Setaddnewadd(true);
  };

  const addnewaddress1 = async () => {
    console.log("jbjjjbjb");
    Setaddnewadd1(true);
  };

  const addAddress = async (_id) => {
    setnewAddres(_id.address);
    formValue["withAddress"] = _id.address;
  };

  const deleteAddress = async (_id) => {
    alert("Are You Delete This Address");
    var data = {
      apiUrl: apiService.removeAddress,
      payload: { _id: _id },
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      showSuccessToast(resp.message);
      getAddress();
    } else {
      showErrorToast(resp.message);
    }
  };

  const cancel = () => {
    setalertTab("show");
    navigate("/dashboard");
  };
  const agree = () => {
    setalertTab("remove");
    localStorage.setItem("withdraw_status", "true");
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    if (response.status) {
      setsitekycStatus(response.data.kycStatus);
    } else {
      setkycStatus(0);
    }
  };

  const navigateKyc = async () => {
    navigate("/kyc");
  };

  return (
    <main className="deposit_main_page">
      <section>
        <Home_header />
      </section>

      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : kycStatus == 1 ? (
        <>
          <section className="smart_bull_deposit_crypto_section">
            <div className="container">
              <div className="smart_bull_deposit_title">Withdraw Crypto</div>

              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-7">
                  <div className="smart_bull_deposit_select_coin_box to_address_withdraw">
                    <h3>Select Coin</h3>
                    {/* Dropdown */}
                    <div className="crypto_img_dropdown">
                      <div
                        type="button"
                        class="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="dropdown-header">
                          <div>
                            {" "}
                            {Imageref.current != "" ? (
                              <img src={Imageref.current} />
                            ) : (
                              ""
                            )}
                            <span>
                              {" "}
                              {currencyref.current == "" ||
                              currencyref.current == null ||
                              currencyref.current == undefined
                                ? "Select Currency"
                                : currencyref.current}
                            </span>
                          </div>

                          <span className="dropdown-arrow">
                            <i class="fa-solid fa-chevron-down"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    {show_otpref.current == false &&
                    currentcurrencyref.current.currencyType == "2" ? (
                      <div className="to_address_withdraw">
                        <h3>Select Network</h3>
                        <div className="crypto_img_dropdown">
                          <div
                            type="button"
                            class="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#selectnetwork"
                          >
                            <div className="dropdown-header">
                              <div>
                                {Networksref.current == "" ? (
                                  <span> Select Network </span>
                                ) : (
                                  <span>{Networksref.current}</span>
                                )}
                              </div>

                              <span className="dropdown-arrow">
                                <i class="fa-solid fa-chevron-down"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {withdrawType == "1" && show_otpref.current == false ? (
                      <div className="to_address_withdraw">
                        <h3>To Address</h3>
                        <div className="to_address_withdraw_in">
                          <input
                            type="text"
                            autocomplete="off"
                            placeholder="Enter To Address"
                            name="Address"
                            className="input"
                            value={newAddres}
                            onChange={handleChangs}
                          />
                          <p
                            type="button"
                            class="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal_2"
                          >
                            Saved Addresses
                          </p>
                        </div>
                        <div>
                          {withAddressValidate == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr.withAddress}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                    {show_otpref.current == false ? (
                      <div className="to_address_withdraw">
                        <h3>Amount</h3>
                        <div className="to_address_withdraw_in">
                          <input
                            type="number"
                            pattern="[0-9]*"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder="Enter Amount"
                            min={1}
                            minLength={1}
                            name="amount"
                            value={amount}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          {amountValidate == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr.amount}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {show_otpref.current == true ? (
                      <div div className="mt-3">
                        {localStorage.getItem("tfa_status") == 0 || 1 ? (
                          <>
                            <div className="to_address_withdraw">
                              <h3>2FA</h3>
                              <div className="to_address_withdraw_in">
                                <input
                                  type="text"
                                  autocomplete="off"
                                  placeholder="Enter 2FA Code"
                                  name="tfa"
                                  value={tfa}
                                  onChange={handleChange}
                                />
                              </div>
                              {tfaValidate == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {validationnErr.tfa}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}{" "}
                        <>
                          {" "}
                          <div className="to_address_withdraw">
                            <h3>Withdraw OTP</h3>
                            <div className="to_address_withdraw_in">
                              <input
                                type="text"
                                autocomplete="off"
                                placeholder="Enter Withdraw OTP"
                                name="withdraw_otp"
                                value={withdraw_otp}
                                onChange={handleChange}
                                className="walletr_inpur"
                              />
                            </div>
                            {otpValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.withdraw_otp}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </>{" "}
                      </div>
                    ) : (
                      ""
                    )}

                    {show_otpref.current == false && viewref.current == true ? (
                      <div className="balance_balance w-100">
                        <div className="withdraw_fees">
                          <p>Fees</p>
                          <p className="withdraw_fees_percent">
                            {currentcurrencyref.current.withdrawFee}
                          </p>
                        </div>

                        <div className="withdraw_fees">
                          <p>Minimum withdraw</p>
                          <p className="withdraw_fees_percent">
                            {" "}
                            {currentcurrencyref.current.minWithdrawLimit}{" "}
                          </p>
                        </div>
                        <div className="withdraw_fees">
                          <p>Maximum withdraw</p>
                          <p className="withdraw_fees_percent">
                            {" "}
                            {currentcurrencyref.current.maxWithdrawLimit}{" "}
                          </p>
                        </div>

                        {balanceref.current.network != "" ? (
                          <div className="withdraw_fees">
                            <p>Network</p>
                            <p className="withdraw_fees_percent">
                              {" "}
                              {balanceref.current.network}{" "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="withdraw_submit_btn">
                      {buttonLoader == false ? (
                        <div className="flexbutton">
                          {localStorage.getItem("tfa_status") == 0 ? (
                            <button onClick={() => nav_page("/security")}>
                              Enable 2FA
                            </button>
                          ) : show_otpref.current == true ? (
                            <button onClick={() => withdrawSubmit()}>
                              Submit
                            </button>
                          ) : (
                            <button onClick={() => withdrawPreview()}>
                              Submit
                            </button>
                          )}
                        </div>
                      ) : (
                        <button>Loading...</button>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-5">
                <section className="faq-section">
                  <div className="faq_list">
                    <h3>FAQ</h3>
                    <div className="Accordion">
                      <Accordion className="Accordionfaq">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          1. How do I deposit crypto into my Smart Bull account?
                        </AccordionSummary>
                        <AccordionDetails>
                          To deposit is to transfer existing digital assets into
                          an account at Smart Bull. If it's a transfer from
                          on-chain sources, its status will be available on the
                          blockchain used for the deposit or transfer.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="Accordionfaq">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          2. What should I do if I don't receive my deposits or if
                          I deposit at an incorrect address?
                        </AccordionSummary>
                        <AccordionDetails>
                          Verify that the transaction was successful and that the
                          funds were sent to the correct address. Use a block
                          explorer for the respective blockchain to check the
                          transaction status and address details. Contact the
                          exchange's customer support. Provide them with the
                          transaction ID, deposit address, and any other relevant
                          details. They may be able to assist you in locating the
                          funds.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="Accordionfaq">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          3. What should I do if I deposit the wrong crypto?{" "}
                        </AccordionSummary>
                        <AccordionDetails>
                          If you sent the wrong cryptocurrency to your  wallet and
                          the wallet does not support the cryptocurrency you
                          mistakenly sent, recovery options may be limited.
                          Contact the exchange's customer support. Provide them
                          with the transaction ID, deposit address, and any other
                          relevant details.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="Accordionfaq">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          4. Smart Bull allows for depositing cryptocurrencies
                          into wallets.
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul className="ml-3">
                            <li>BTC</li>
                            <li>BSC</li>
                            <li>TRX</li>
                            <li>MATIC</li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </section>
              </div> */}
              </div>
            </div>
          </section>
          <section className="deposit_history_tabel_section">
            <div className="container">
              <div className="deposit_history_title">Recent Withdrawals</div>
              <div className="table-responsive deposite_tabel_border">
                <table className="table">
                  <thead>
                    <tr className="tabel_bg">
                      <th scope="col" className="starts">
                        Date
                      </th>
                      <th scope="col">Time</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Transaction Id</th>
                      <th scope="col" className="ends">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawHistory && withdrawHistory.length > 0 ? (
                      withdrawHistory.map((item, i) => {
                        return (
                          <tr className="history_tabel_bottom_border">
                            <th scope="row">
                              {Moment(item.created_at).format("DD.MM.YYYY")}
                            </th>
                            <td>{Moment(item.created_at).format("HH:mm a")}</td>
                            <td>{item.currency}</td>
                            <td> {parseFloat(item.amount).toFixed(8)}</td>
                            <td>{item.txn_id}</td>
                            <td>
                              {" "}
                              {item.status == "Completed" ? (
                                <p className="text-success">{item.status}</p>
                              ) : (
                                <p className="text-danger">{item.status}</p>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <td colSpan={6}>
                        <div className="empty_data">
                          <div className="empty_data_img">
                            <img src={require("../images/No-data.png")} />
                          </div>

                          <div className="no_records_text">
                            No Records Found
                          </div>
                        </div>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          
        </>
      ) : (
        <div className="deposit">
          <div className="container">
            <h2 className="depositbuttonh">
              Please complete the KYC verification process to gain access to the
              page.
            </h2>
            <button className="primary-btn depositbutton" onClick={navigateKyc}>
              {" "}
              Verify KYC Now
            </button>
          </div>
        </div>
      )}

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Coin{" "}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="top_coins_list">
                <h3>Top</h3>
                <div className="top_coins_list_buttons">
                  {allCurrencyref.current && allCurrencyref.current.length > 0
                    ? allCurrencyref.current.slice(0, 5).map((item, i) => {
                        return (
                          <p
                            data-bs-dismiss="modal"
                            onClick={() => onSelect(item)}
                          >
                            {" "}
                            {item.label}{" "}
                          </p>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            <div class="moadl_top_coins_list_items px-3">
              {allCurrencyref.current && allCurrencyref.current.length > 0
                ? allCurrencyref.current.map((item, i) => {
                    return (
                      <div
                        className="moadl_top_coin_items"
                        data-bs-dismiss="modal"
                        onClick={() => onSelect(item)}
                      >
                        <img src={item.imgurl} />
                        <div className="top_coin_items">
                          <h3>{item.currencyName} </h3>
                          <p>{item.label}</p>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="selectnetwork"
        tabindex="-1"
        aria-labelledby="selectnetworkLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="selectnetworkLabel">
                Select Network{" "}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="px-4 py-2">
              <p className="network_warning_msg">
                <i class="ri-error-warning-line"></i>Ensure that the selected
                deposit network is the same as the withdrawal network.
                Otherwise, your assets could be lost.
              </p>

              {network_currencyref.current &&
              network_currencyref.current.length > 0
                ? network_currencyref.current.map((item, i) => {
                    return (
                      <div
                        className="moadl_top_coin_items"
                        data-bs-dismiss="modal"
                        onClick={() => onSelect_network(item)}
                      >
                        <div className="top_coin_items">
                          <h3>{item.label} </h3>
                          <p>{item.value}</p>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    
      {/* <!-- Modal-2 --> */}
      <div
        className="modal fade"
        id="exampleModal_2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          {addnewaddref.current === false ? (
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select / Add Address
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {allAddressref.current && allAddressref.current.length > 0 ? (
                  allAddressref.current.map((data, i) => {
                    return (
                      <div class="formbold-file-list formbold-mb-5">
                        <div class="formbold-file-item d-flex justify-content-center">
                          <span
                            class="formbold-file-name cursor-pointer"
                            onClick={() => addAddress(data)}
                            data-dismiss="modal"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            {data.address}
                          </span>
                          <button>
                            {data.currency}
                            <i
                              class="ri-delete-bin-line text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteAddress(data._id)}
                            ></i>
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="select_address_img">
                      <img
                        src={require("../images/dashboard/network_img.png")}
                        alt="Network"
                      />
                    </div>
                    <div className="modal_network_text text-center">
                      <p>
                        Save your frequently used addresses to the address book
                        for convenient withdrawals. Differentiate addresses
                        <br /> by adding remarks.
                      </p>
                    </div>
                  </>
                )}
              </div>

              <div className="add_new_address">
                <button type="button" className="btn" onClick={addnewaddress}>
                  Add New Address
                </button>
              </div>
            </div>
          ) : addnewaddref1.current === false ? (
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add New Address
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="px-5 py-3">
                <div>
                  <div className="">
                    <div
                      className="crypto_img_dropdown"
                      onClick={addnewaddress1}
                    >
                      <div type="button">
                        <div className="dropdown-header">
                          <div>
                            {" "}
                            {newImageref.current == "" ? (
                              <img src={require("../images/usdt.png")} />
                            ) : (
                              <img src={newImageref.current} />
                            )}
                            <span className="spn_usdt">
                              {newCurrencyref.current}
                            </span>
                            <span className="spn_tether">
                              ( {newFullnameref.current})
                            </span>
                          </div>

                          <span className="dropdown-arrow">
                            <i class="fa-solid fa-chevron-down"></i>
                          </span>
                        </div>
                        {newCurrencyref.current == "" ? (
                          <p style={{ color: "red" }}>Currency is required</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="to_address_withdraw">
                      <h3>Wallet Address</h3>
                      <div className="to_address_withdraw_in">
                        <input
                          type="text"
                          autocomplete="off"
                          placeholder="Enter To Address"
                          name="Address"
                          value={newAddres}
                          onChange={(e) => bankdetails(e.target.value)}
                          class="form-control p-0 m-0 inputwidth"
                        />
                      </div>
                      {newAddres == "" ||
                      newAddres == undefined ||
                      newAddres == null ? (
                        <p style={{ color: "red" }}>Address is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="to_address_withdraw">
                      <h3>Network</h3>
                      <div className="to_address_withdraw_in">
                        <input
                          class="form-control p-0 m-0"
                          type="text"
                          autocomplete="off"
                          placeholder="Enter network (Optional)"
                          value={newNetwork}
                          onChange={(e) => setnewNetwork(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="withdraw_submit_btn add_address_btn">
                      <button onClick={Addaddress}> Add Address</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Coin{" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  <div>
                    <div class="">
                      <div className="addcoins-head">
                        {allCurrencyref.current &&
                        allCurrencyref.current.length > 0
                          ? allCurrencyref.current.map((item, i) => {
                              console.log(item, "items");
                              return (
                                <div
                                  className="addcoins"
                                  onClick={() => onSelect1(item)}
                                >
                                  <img src={item.imgurl} />
                                  <div className="top_coin_items">
                                    <h3>{item.label}</h3>
                                    <p>{item.currencyName}</p>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Deposit;
