import React, { useEffect } from "react";
import useState from "react-usestateref";
import Home_header from "./Home_header";
import Accordion from "@mui/material/Accordion";
import { Link, useNavigate } from "react-router-dom";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Bars } from "react-loader-spinner";

import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Moment from "moment";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Deposit = () => {
  const navigate = useNavigate();

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("USDT");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState();
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus, kycStatusref] = useState(1);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [Fullname, Setfullname, Fullnameref] = useState("Tether");
  const [Image, setImage, Imageref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [refreshStatus, setrefreshStatus] = useState(false);
  
  useEffect(() => {
     getAllcurrency();
     getKYCstatus();
    getdepositHistory();
    getTransaction();
  }, [0]);
  console.log(
    addressref.current,
    "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
  );

  const refresh =()=>{
    setrefreshStatus(true);
    getTransaction();
    getdepositHistory();
  }
  const getTransaction = async () => {

    var data = {
      apiUrl: apiService.transaction,
    };
  // setSiteLoader(true)

    var resp = await getMethod(data);
    // setSiteLoader(false)
    setrefreshStatus(false)

    if (resp.message == true) {
      getdepositHistory();
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      console.log(allCryptoref.current, "allcrypto");
      for (var i = 0; i < data.length; i++) {
        if (data[i].depositStatus == "Active") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
            currencyName: data[i].currencyName,
            imgurl: data[i].Currency_image,
          };
          currArrayCrypto.push(obj);
        }
      }
      console.log("network_currencyref===", currArrayCrypto[0]);
      setallCurrency(currArrayCrypto);
    setcurrency(currArrayCrypto[0].label);
    Setfullname(currArrayCrypto[0].currencyName);
    setImage(currArrayCrypto[0].imgurl);
    setcointype(currArrayCrypto[0].coinType);
    if(currArrayCrypto[0].coinType=="1"){
      onSelect(currArrayCrypto[0]);
    }
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    setSiteLoader(true);
    var getKYC = await getMethod(data);
    setSiteLoader(false);

    console.log("getkyrefreshc===", getKYC);

    if (getKYC.status) {
      console.log(getKYC.Message.kycstatus, "========");
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      // setkycStatus(0);
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc1===", response);
    if (response.status) {
      console.log(response.data.kycStatus, "==kyc======", kycStatus);
      setsitekycStatus(response.data.kycStatus);
    } else {
      // setkycStatus(0);
    }
  };

  const getdepositHistory = async () => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: 1 },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
    }
  };

  const onSelect = async (option) => {
    console.log(option, "-=-onSelecttop");

    setcur_network([]);
    setnet_default("");
    setcurrency(option.label);
    Setfullname(option.currencyName);
    setImage(option.imgurl);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setcur_currency(currencydata);

      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].label);
      }

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };
        setview("load");
        var resp = await postMethod(data);
        console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
          setview("view");
          setAddress(resp.Message);
          console.log(
            addressref.current,
            "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
          );
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      } else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=fiat deposit resp-=-=--");
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      }
    }
    //}
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
     if (toastId) {
       toast.dismiss(toastId);
     }
     const newToastId = toast.success(message);
     setToastId(newToastId);
   };
 
   const showErrorToast = (message) => {
     if (toastId) {
       toast.dismiss(toastId);
     }
     const newToastId = toast.error(message);
     setToastId(newToastId);
   };
 
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast("Address copied");
  };

  const navigateKyc = async () => {
    navigate("/kyc");
  };
  const onSelect_network = async (option) => {
    setNetworks(option.label);
    console.log(option, "-=-onSelect_network");
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currency: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      console.log("call here 1111", obj);
      var data = {
        apiUrl: apiService.generateAddress_network,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview("view");
        setAddress(resp.Message);
      } else {
        //toast.error("Something went wrong, please try again later");
      }
    }
  };

  return (
    <main className="deposit_main_page">
      <section>
        <Home_header />
      </section>
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (

        kycStatusref.current == 1 ? (
          <>
            <section className="smart_bull_deposit_crypto_section">
              <div className="container">
                <div className="smart_bull_deposit_title">Deposit Crypto</div>
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-7">
                    <div className="smart_bull_deposit_select_coin_box">
                      <h4>Select Coin</h4>
                      <div class="btn-group deposit_coin_dropdown">
                        <button
                          type="button"
                          class="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <div className="deposit_select_coin-dropdown_list">
                            <div>

                              {Imageref.current == "" ? (
                                ""
                              ) : (
                                <img src={Imageref.current} />
                              )}
                              <h3>
                                {Imageref.current == "" ? (
                                  <span className="spn_usdt">
                                    Select Currency
                                  </span>
                                ) : (
                                  <span className="spn_usdt">
                                    {currencyref.current}
                                  </span>
                                )}{" "}
                                <span>
                                  {" "}
                                  {Imageref.current == "" ? (
                                    ""
                                  ) : (
                                    <span className="spn_tether">
                                      {[]}
                                    </span>
                                  )}
                                </span>
                              </h3>
                            </div>
                            <span className="dropdown-arrow">
                              <i class="fa-solid fa-chevron-down"></i>
                            </span>
                          </div>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <button class="dropdown-item" type="button">
                              Btc
                            </button>
                          </li>
                          <li>
                            <button class="dropdown-item" type="button">
                              ETH
                            </button>
                          </li>
                          <li>
                            <button class="dropdown-item" type="button">
                              BTC
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="bc-list-coins">
                        {allCurrencyref.current &&
                          allCurrencyref.current.length > 0
                          ? allCurrencyref.current.slice(0, 5).map((item, i) => {
                            return (
                              <div
                                className="btc-list1"
                                onClick={() => onSelect(item)}
                              >
                                <img src={item.imgurl} />
                                <p>{item.label}</p>
                              </div>
                            );
                          })
                          : ""}
                      </div>
                      {cur_currencyref.current.currencyType == "2" ? (
                        <div class="btn-group deposit_coin_dropdown depositnetw">
                          <button
                            type="button"
                            class="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal2"
                          >
                            <div className="deposit_select_coin-dropdown_list ">
                              <div>
                                <h3>
                                  {Networksref.current == "" ? (
                                    <span className="spn_usdt">
                                      Select Network
                                    </span>
                                  ) : (
                                    <span className="spn_usdt">
                                      {Networksref.current}
                                    </span>
                                  )}
                                </h3>
                              </div>
                              <span className="dropdown-arrow">
                                <i class="fa-solid fa-chevron-down"></i>
                              </span>
                            </div>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {addressref.current == undefined ? (
                        ""
                      ) : (
                        <>
                          <div className="deposit_qr_ass">
                            <img
                              src={
                                addressref.current == undefined
                                  ? ""
                                  : addressref.current.qrcode
                              }
                            />
                          </div>
                          <div className="deposit_address">
                            <h3>Address</h3>
                            <div className="deposit_copy_address">
                              <p>
                                {" "}
                                {addressref.current == undefined
                                  ? ""
                                  : addressref.current.address}
                              </p>
                              <i
                                class="ri-file-copy-fill cursor"
                                onClick={() => copy(addressref.current.address)}
                              ></i>
                            </div>
                          </div>
                      {cur_currencyref.current.currencyType == "2" ? (
                          <p className="text-red"> <small>Deposit only {Networksref.current} network  {currencyref.current} otherwise your fund will loss...</small></p>
                      ):""}
                        </>
                      )}
                    </div>
                  </div>
{/* 
                  <div className="col-lg-5">
                    <section className="faq-section">
                      <div className="faq_list">
                        <h3>FAQ</h3>
                        <div className="Accordion">
                          <Accordion className="Accordionfaq">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              1. How do I deposit crypto into my Smart Bull
                              account?
                            </AccordionSummary>
                            <AccordionDetails>
                              To deposit is to transfer existing digital assets
                              into an account at Smart Bull. If it's a transfer
                              from on-chain sources, its status will be available
                              on the blockchain used for the deposit or transfer.
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className="Accordionfaq">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              2. What should I do if I don't receive my deposits
                              or if I deposit at an incorrect address?
                            </AccordionSummary>
                            <AccordionDetails>
                              Verify that the transaction was successful and that
                              the funds were sent to the correct address. Use a
                              block explorer for the respective blockchain to
                              check the transaction status and address details.
                              Contact the exchange's customer support. Provide
                              them with the transaction ID, deposit address, and
                              any other relevant details. They may be able to
                              assist you in locating the funds.
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className="Accordionfaq">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel3-content"
                              id="panel3-header"
                            >
                              3. What should I do if I deposit the wrong crypto?{" "}
                            </AccordionSummary>
                            <AccordionDetails>
                              If you sent the wrong cryptocurrency to your  wallet
                              and the wallet does not support the cryptocurrency
                              you mistakenly sent, recovery options may be
                              limited. Contact the exchange's customer support.
                              Provide them with the transaction ID, deposit
                              address, and any other relevant details.
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className="Accordionfaq">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel3-content"
                              id="panel3-header"
                            >
                              4. Smart Bull allows for depositing cryptocurrencies
                              into wallets.
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="ml-3">
                                <li>BTC</li>
                                <li>BSC</li>
                                <li>TRX</li>
                                <li>MATIC</li>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </section>
                  </div> */}
                </div>
              </div>
            </section>

            {kycStatus == 1 ? (
              <section className="deposit_history_tabel_section">
                <div className="container">
                  <div className="refresh">
                  <div className="deposit_history_title" >Deposit History</div>
                  <div onClick={refresh}>
                    <button className="primary-btn depositbutton">
                      {refreshStatus ==true ?
                    <i class="fa-solid fa-spinner fa-spin"></i>
                      :
                      ""
                      } {" " }
                    Refresh
                    </button>
                  </div>
                 </div>
                  <div className="table-responsive deposite_tabel_border">
                    <table className="table">
                      <thead>
                        <tr className="tabel_bg">
                          <th scope="col" className="starts">
                            Date
                          </th>
                          <th scope="col">Time</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Transaction Id</th>
                          <th scope="col" className="ends">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {depositHistory && depositHistory.length > 0 ? (
                          depositHistory.map((item, i) => {
                            return (
                              <tr className="history_tabel_bottom_border">
                                <th scope="row">
                                  {" "}
                                  {Moment(item.date).format("YYYY-MM-DD")}
                                </th>
                                <td>{Moment(item.date).format("HH:mm:ss")}</td>
                                <td>{item.currencySymbol}</td>
                                <td>{parseFloat(item.amount).toFixed(8)}</td>
                                <td>
                                  {" "}
                                  {item.txnid}{" "}
                                  <i
                                    class="fa fa-clone"
                                    aria-hidden="true"
                                    onClick={() => copy(item.txnid)}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </td>
                                <td className="text-success">Completed</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <div className="empty_data">
                                <div className="empty_data_img"></div>
                                <div className="no_records_text">
                                  No Records Found{" "}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            ) : (
              " "
            )}
          </>
        ) : (
          <div className="deposit"> 
            <div className="container">
              <h2 className="depositbuttonh">
                Please complete the KYC verification process to gain access to the
                page.
              </h2>
              <button className="primary-btn depositbutton" onClick={navigateKyc}>
                {" "}
                Verify KYC Now
              </button>
            </div>
          </div>
        ))}

      {/* Modal */}
      <div class="modal fade " id="myModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content select_coin">
            <div class="modal-header">
              <h4 class="modal-title">Coin</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <section className="top_coin_section" data-dismiss="modal">
                <h3>Top</h3>
                <p>
                  {allCurrencyref.current && allCurrencyref.current.length > 0
                    ? allCurrencyref.current.slice(0, 5).map((item, i) => {
                      return (
                        <span
                          className="top_coin_item"
                          onClick={() => onSelect(item)}
                        >
                          {" "}
                          {item.label}{" "}
                        </span>
                      );
                    })
                    : ""}
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Button trigger modal --> */}
      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Coin{" "}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="top_coins_list">
                <h3>Top</h3>
                <div className="top_coins_list_buttons">
                  {allCurrencyref.current && allCurrencyref.current.length > 0
                    ? allCurrencyref.current.slice(0, 5).map((item, i) => {
                      return (
                        <p
                          onClick={() => onSelect(item)}
                          data-bs-dismiss="modal"
                        >
                          {item.label}
                        </p>
                      );
                    })
                    : ""}
                </div>
              </div>
            </div>
            <div class="moadl_top_coins_list_items">
              {allCurrencyref.current && allCurrencyref.current.length > 0
                ? allCurrencyref.current.map((item, i) => {
                  console.log(item, "------");
                  return (
                    <div
                      className="moadl_top_coin_items"
                      data-bs-dismiss="modal"
                      onClick={() => onSelect(item)}
                    >
                      <img src={item.imgurl} />
                      <div className="top_coin_items">
                        {/* <h3>{item.currencyName}</h3> */}
                        <p>{item.label}</p>
                      </div>
                    </div>
                  );
                })
                : ""}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel2">
                Set Network{" "}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <div class="modal-body">
              <div className="top_coins_list">
                <h3>Top</h3>
                <div className="top_coins_list_buttons">
                {allCurrencyref.current &&
                        allCurrencyref.current.length > 0 
                        ? allCurrencyref.current
                          .slice(0, 5)
                          .map((item, i) => {
                            return (
                                  <p onClick={() => onSelect(item)} 
                                  data-bs-dismiss="modal"
                                  >{item.label}</p>
                                );
                              })
                            : ""}
                </div>
                 
              </div>
            </div> */}
            {/* <div class="moadl_top_coins_list_item">
              {network_currencyref.current &&
              network_currencyref.current.length > 0
                ? network_currencyref.current.map((item, i) => {
                    console.log(item, "network");
                    return (
                      <div
                        className="moadl_top_coin_items"
                        data-bs-dismiss="modal"
                        onClick={() => onSelect_network(item)}
                      >
                        {/* <img src={item.imgurl} />  
                        <div className="top_coin_items">
                          {/* <h3>{item.currencyName}</h3>  
                          <p>{item.label}</p>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {/* // {allCurrencyref.current &&
              //           allCurrencyref.current.length > 0
              //           ? allCurrencyref.current
              //             .slice(0, 5)
              //             .map((item, i) => {
              //               console.log(item,"------")
              //               return (
              // <div className="moadl_top_coin_items"  data-bs-dismiss="modal" onClick={() => onSelect(item)}>
              //                  <img src={item.imgurl} />
              //                 <div className="top_coin_items">
              //     <h3>{item.currencyName}</h3>
              //     <p>{item.label}</p>
              //     </div>
              //   </div>
              //    );
              //   })
              // : ""}  
            </div> */}
            <div className="modal-body network_warning_msg">
              <p>
                {" "}
                <i class="ri-error-warning-line"></i> Ensure that the selected
                deposit network is the same as the withdrawal network.
                Otherwise, your assets could be lost.
              </p>

              {network_currencyref.current &&
                network_currencyref.current.length > 0
                ? network_currencyref.current.map((item, i) => {
                  console.log(item, "------")
                  return (
                    <div className="network_content" data-bs-dismiss="modal" onClick={() => onSelect_network(item)}>
                      <img src={item.imgurl} />
                      <div className="top_coin_items top_coin_itemsdrop">
                        <h3>{item.currencyName}</h3>
                        <span>{item.label}</span>
                      </div>
                    </div>
                  );
                })
                : ""}

            </div>
            {/* <div class="moadl_top_coins_list_items">


          

      {network_currencyref.current &&
                              network_currencyref.current.length > 0
                                ? network_currencyref.current.map((item, i) => {
                                    console.log(item, "network");
                                    return (
                                      <div className="moadl_top_coin_items"  data-bs-dismiss="modal"   onClick={() => onSelect_network(item)}>
                                <img src={item.imgurl} />
                               <div className="top_coin_items">
                   <h3>{item.currencyName}</h3>
                   <p>{item.label}</p>
                   </div>
                   </div>
                                    );
                                  })
                                : ""}
            
            </div> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Deposit;
