import React from "react";
import { useEffect } from "react";
import useState from "react-usestateref";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Home_header.js";
import Footer from "./Footer.js";
import { Link } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();

  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  const [siteData, setSiteData] = useState({});
  const [top_volume, set_top_volume] = useState([]);
  const [top_new_list, set_top_new_list] = useState([]);
  const [loader, setloader, loaderref] = useState(false);
  const [loginCheck, setloginCheck, loginCheckref] = useState(false);

  const fetch_site_data = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      setSiteData(resp.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetch_site_data();
    api1();
    api2();
    let userToken = localStorage.getItem("user_token");
    if (userToken != null) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  var api1 = async () => {
    var data = {
      apiUrl: apiService.hotPairs,
    };
    var resp = await getMethod(data);
    set_top_volume(resp.topgainers);
  };
  var api2 = async () => {
    var data = {
      apiUrl: apiService.newPairs,
    };
    var resp = await getMethod(data);
    set_top_new_list(resp.hotpairs);
  };

  const nav_page = () => {
    navigate("/register");
  };

  return (
    <main className="smart_bull_landing_page">
      <section>
        <Header />
      </section>
      <section className="smart_hero_section">
        <div className="container">
          <div className="smart-hero-section-content">
            <p>Trade confidently on the</p>
            {/* <h1>fastest-growing exchange</h1> */}
            <h1>Fastest Growing Crypto Exchange</h1>
            <h3>
              <span className="span-blue">Join us </span>for seamless crypto
              trading.
            </h3>
            {loginCheckref.current === false ? (
              <button className="reg-button" onClick={nav_page}>
                Register Now
              </button>
            ) : (
              ""
            )}
          </div>
          {/* <h2><b>Fastest Growing Crypto Exchange </b></h2> */}
          <section className="crypto-coin-slider-section">
            <Slider {...sliderSettings}>
              {/* {top_volume && top_volume.length > 0
            ? top_volume.map((obj, i) => {
                return (
                  <div className="logo_client_img token-dev">
                    <img
                      src={obj.imageUrl}
                      alt="BTC Icon"
                      width={23}
                      height={22}
                    />
                    <h4>{obj.name}</h4>
                    {obj.change24Hour <= 0 ? (
                      <p className="text-success">
                        <i className="ri-arrow-up-s-fill"></i>{" "}
                        {obj.change24Hour}%
                      </p>
                    ) : (
                      <p className="text-success">
                        <i className="ri-arrow-up-s-fill"></i>{" "}
                        {obj.change24Hour}%
                      </p>
                    )}
                  </div>
                );
              })
            : ""} */}
              <div className="logo_client_img token-dev">
                <img
                  src={require("../images/landing/Experience-slider1.webp")}
                  alt="BTC Icon"
                />
              </div>
              <div className="logo_client_img token-dev">
                <img
                  src={require("../images/landing/Invite-slider2.webp")}
                  alt="BTC Icon"
                />
              </div>
              <div className="logo_client_img token-dev">
                <img
                  src={require("../images/landing/Learn-slider3.webp")}
                  alt="BTC Icon"
                />
              </div>
              <div className="logo_client_img token-dev">
                <img
                  src={require("../images/landing/Stake-slider4.webp")}
                  alt="BTC Icon"
                />
              </div>
              <div className="logo_client_img token-dev">
                <img
                  src={require("../images/landing/Trade-slider5.webp")}
                  alt="BTC Icon"
                />
              </div>
              <div className="logo_client_img token-dev">
                <img
                  src={require("../images/landing/Proud-slider6.webp")}
                  alt="BTC Icon"
                />
              </div>
            </Slider>
          </section>
        </div>
      </section>

      <section className="popular-cryptocurrencies-section">
        <div className="container">
          <div className="popular-cryptocurrencies">
            <h2>Popular Cryptocurrencies</h2>
            <div className="popular_tabel_border">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    HOT LIST
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    NEW LIST
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Last Price</th>
                          <th scope="col">Volume</th>
                          {/* <th scope="col">Per/Day Chart</th> */}
                          <th scope="col">24h Change</th>
                          {/* <th scope="col">Trade</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {top_volume && top_volume.length > 0 ? (
                          top_volume.map((obj, i) => {
                            return (
                              <tr>
                                <th scope="row">
                                  <div className="name-coin-item">
                                    <img src={obj.imageUrl} width={20} />
                                    <p>
                                      {obj.name} <span>{obj.symbol}</span>
                                    </p>
                                  </div>
                                </th>
                                <td>${obj.price}</td>
                                <td>{obj.volume}</td>
                                {/* {obj.change24Hour <= 0 ? (
                                <>
                                  <td>
                                    <img
                                      src={require("../images/landing/volume-red.webp")}
                                    />
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <img
                                      src={require("../images/landing/volume-green.webp")}
                                    />
                                  </td>
                                </>
                              )} */}

                                {obj.change24Hour <= 0 ? (
                                  <>
                                    <td className="text-danger">
                                      {parseFloat(obj.change24Hour).toFixed(2)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="text-success">
                                      +{parseFloat(obj.change24Hour).toFixed(2)}
                                    </td>
                                  </>
                                )}
                                {/* <td className="trade-table-btn">
                                <button>Trade</button>
                              </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4}>
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img src={require("../images/No-data.png")} />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/binance-icon.webp")}
                            />
                            <p>
                              Binance Coin <span>BNB</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/bitcoin-icon.webp")}
                            />
                            <p>
                              Bitcoin <span>BTC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-success">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/open-network-icon.webp")}
                            />
                            <p>
                              The Open Network <span>TONCOIN</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-sucess">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/ehhereum-icon.webp")}
                            />
                            <p>
                              Ethereum <span>ETH</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/solana-icon.webp")}
                            />
                            <p>
                              Solana <span>SOL</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/usd-icon.webp")}
                            />
                            <p>
                              USD Coin <span>USDC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Last Price</th>
                          <th scope="col">Volume</th>
                          {/* <th scope="col">Per/Day Chart</th> */}
                          <th scope="col">24h Change</th>
                          {/* <th scope="col">Trade</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {top_new_list && top_new_list.length > 0 ? (
                          top_new_list.map((obj, i) => {
                            return (
                              <tr>
                                <th scope="row">
                                  <div className="name-coin-item">
                                    <img src={obj.imageUrl} width={20} />
                                    <p>
                                      {obj.name} <span>{obj.symbol}</span>
                                    </p>
                                  </div>
                                </th>
                                <td>${obj.price}</td>
                                <td>{obj.volume24Hour}</td>
                                {/* {obj.change24Hour <= 0 ? (
                                <>
                                  <td>
                                    <img
                                      src={require("../images/landing/volume-red.webp")}
                                    />
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <img
                                      src={require("../images/landing/volume-green.webp")}
                                    />
                                  </td>
                                </>
                              )} */}
                                {obj.change24Hour <= 0 ? (
                                  <>
                                    <td className="text-danger">
                                      {parseFloat(obj.change24Hour).toFixed(2)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="text-success">
                                      +{parseFloat(obj.change24Hour).toFixed(2)}
                                    </td>
                                  </>
                                )}
                                {/* <td className="trade-table-btn">
                                <button>Trade</button>
                              </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4}>
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img src={require("../images/No-data.png")} />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {/* <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/staked-icon.webp")}
                            />
                            <p>
                              Staked Ether <span>STETH</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-success">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/binance-icon.webp")}
                            />
                            <p>
                              Binance Coin <span>BNB</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/bitcoin-icon.webp")}
                            />
                            <p>
                              Bitcoin <span>BTC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-success">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/open-network-icon.webp")}
                            />
                            <p>
                              The Open Network <span>TONCOIN</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-sucess">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/ehhereum-icon.webp")}
                            />
                            <p>
                              Ethereum <span>ETH</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/solana-icon.webp")}
                            />
                            <p>
                              Solana <span>SOL</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/usd-icon.webp")}
                            />
                            <p>
                              USD Coin <span>USDC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="build-your-crypto-section pt-0">
        <div className="container">
          <div className="build-your-crypto-content ">
            {/* <h2>Build Your Crypto Portfolio</h2> */}
            <h2>Get started & build your portfolio! </h2>
            <p>
              Start trading & investing in crypto following these easy steps.
            </p>
            {/* <p>Start your first trade with these easy steps.</p> */}
            <div className="row">
              <div className="col-lg-4">
                <div className="build-your-crypto-steps verify-before-line">
                  <img
                    src={require("../../src/images/landing/verify_icon.webp")}
                    alt="Verify Your Identity"
                    className="light_verify_icon"
                  />
                  <img
                    src={require("../../src/images/landing/dark_verify_icon.png")}
                    className="dark_verify_icon"
                  />
                  <h3>Sign-up & Verify</h3>
                  {/* <h3>Verify Your Identity</h3> */}
                  <p>
                    Complete signing-up with verifying <br /> your identity to
                    start using our exchange.
                    {/* Complete the identity verification
                    <br /> process to secure your account
                    <br /> and transactions. */}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="build-your-crypto-steps verify-before-line">
                  <img
                    src={require("../../src/images/landing/fund_icon.webp")}
                    alt="Fund Your Account"
                    className="light_verify_icon"
                  />
                  <img
                    src={require("../../src/images/landing/dark_fund_acc.png")}
                    className="dark_verify_icon"
                  />
                  <h3>Fund Your Account</h3>
                  <p>
                    Add funds to your account wallet. <br /> You can deposit
                    either crypto assets or fiat.
                    {/* Add funds to your crypto account to start
                    <br /> trading crypto. You can add funds with a<br />{" "}
                    variety of payment methods. */}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="build-your-crypto-steps">
                  <img
                    src={require("../../src/images/landing/trading_icon.webp")}
                    alt="Start Trading"
                    className="light_verify_icon"
                  />
                  <img
                    src={require("../../src/images/landing/dark_trading_icon.png")}
                    className="dark_verify_icon"
                  />
                  <h3>Start Trading</h3>
                  <p>
                    Explore markets & start trading <br /> hundreds of crypto
                    assets on our <br />
                    exchange platform.
                    {/* <br /> Explore markets and execute trades with
                    <br /> ease on our user-friendly platform. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="salient-features-section">
        <div className="container">
          <div className="salient-features">
            <h2>Salient Features of Our Exchange</h2>
            <div className="row">
              <div className="col-lg-8 wide-range-asset-content">
                <div className="col-lg-8">
                  <div className="wide-range">
                    <img
                      src={require("../images/landing/wide_range_icon.webp")}
                      alt="Wide Range of Assets"
                    />
                    <h3>Wide Range of Assets</h3>
                    <p>
                      We provide access to a diverse selection of
                      cryptocurrencies, allowing users to trade various digital
                      assets and explore new investment opportunities.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="Wide-asset">
                    <img
                      src={require("../images/landing/wide_asset.webp")}
                      alt="Wide Asset"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="diversified-crypto">
                  <img
                    src={require("../images/landing/diversified_icon.webp")}
                    alt="Diversified Crypto Portfolio"
                  />
                  <h3>Diversified Crypto Portfolio</h3>
                  <p>
                    Diversify your crypto portfolio for stability and growth.
                    Spread investments across various cryptocurrencies for risk
                    management and potential returns
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="robust-security">
                  <img
                    src={require("../images/landing/robust_icon.webp")}
                    alt="Robust Security Measures"
                  />
                  <h3>Robust Security Measures</h3>
                  <p>
                    We employ advanced security protocols to ensure the safety
                    of our users assets, including multi-factor authentication,
                    cold storage for the majority of funds, and regular security
                    audits.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="robust-security">
                  <img
                    src={require("../images/landing/competitive_icon.webp")}
                    alt="Competitive Trading Fees"
                  />
                  <h3>Competitive Trading Fees</h3>
                  <p>
                    Our platform has significantly lower trading fees compared
                    to other global exchanges. Users can execute trades
                    simultaneously without worrying much about fees
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="robust-security">
                  <img
                    src={require("../images/landing/fastest_support_icon.webp")}
                    alt="Fastest Customer Support"
                  />
                  <h3>Fastest Customer Support</h3>
                  <p>
                    Our dedicated customer support team is available around the
                    clock to assist users with any queries or issues they may
                    encounter, ensuring a smooth trading experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="crypto-trailblazer-section">
        <div className="container">
          <div className="crypto-trailblazer-content">
            <h2>
              Stay ahead and become a Crypto Trailblazer
              <br />
              with Our App!
            </h2>
            <div className="row">
              <div className="col-lg-7">
                <div className="crypto-trailblazer-banner">
                  <img
                    src={require("../images/landing/trailblazer_banner.webp")}
                    className="trailblazer_banner-img"
                    alt="Crypto Trailblazer"
                  />
                  <img
                    src={require("../images/landing/trailblazer_banner-bg.png")}
                    className="trailblazer_banner-bg"
                    alt="Crypto Trailblazer Background"
                  />
                  <img
                    src={require("../images/landing/trailblazer_banner-bg-dark.png")}
                    className="trailblazer_banner-bg-dark"
                    alt="Crypto Trailblazer Background"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="trade-anytime-content">
                  <h3>
                    Trade Anytime,
                    <br />
                    Anywhere.
                  </h3>
                </div>
                <div className="col-lg-6">
                  <div className="coming_soon">
                    <h3>Coming Soon</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="community-section">
        <div className="container">
          <div className="">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h2 className="text-left">Join Our Community</h2>
                <p className="text-left  mt-4">
                  Smart Bull Exchange is global. Join the conversation in any of
                  our worldwide communities.
                </p>

                <div className=" d-flex mt-4">
                  <div className="icon-cards mr-2">
                    <Link target="_blank" to={siteData.twitter_url}>
                      {" "}
                      <img
                        src={require("../images/landing/twitter-x.webp")}
                      />{" "}
                    </Link>
                  </div>
                  <div className="icon-cards mx-2">
                    <Link target="_blank" to={siteData.insta_url}>
                      {" "}
                      <img
                        src={require("../images/landing/instagram.png")}
                      />{" "}
                    </Link>
                  </div>
                  <div className="icon-cards mx-2">
                    <Link target="_blank" to={siteData.youtube_url}>
                      <img
                        src={require("../images/landing/yt.png")}
                      />
                    </Link>
                  </div>
                  <div className="icon-cards mx-2">
                    <Link target="_blank" to={siteData.fb_url}>
                      <img
                        src={require("../images/landing/facebook-footer.webp")}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={require("../images/Community.png")}
                  className="community-image"
                />
                <img
                  src={require("../images/Community_dark.png")}
                  className="community_image_dark"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="start-your-crypto-section">
        <div className="container">
          <div className="start-your-crypto-content">
            <h2>Start Your Crypto Journey Now!</h2>
            {/* {loginCheckref.current === false ? ( */}
            <button className="reg-button" onClick={nav_page}>
              Get Started
            </button>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
      </section>

      <section className="smart-footer">
        <Footer />
      </section>
    </main>
  );
};

export default LandingPage;
