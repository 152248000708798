import useState from "react-usestateref";
import React, { useEffect } from "react";
import Home_header from "./Home_header";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Modal from "bootstrap/js/dist/modal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "./Footer.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Moment from "moment";
import { toast } from "react-toastify";
import { postMethod, getMethod } from "../core/service/common.api";
import "bootstrap/dist/css/bootstrap.min.css";
import { Bars } from 'react-loader-spinner'
import "bootstrap/dist/js/bootstrap.bundle.min";

import apiService from "../core/service/detail";

const Staking = () => {
  const [stakeDetails, setstakeDetails] = useState([]);
  const [userBalance, setuserBalance] = useState([]);
  const [currentPack, setcurrentPack] = useState("");
  const [breakpack, setbreakpack] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);

  const [stakeBalance, setStakeBalance] = useState(0);

  const [authToken, setauthToken] = useState(false);

  const [duration, setDuration] = useState("15days");
  const [interestRate, setInterestRate] = useState(0);
  const [amount, setAmount] = useState("");
  const [interestAmount, setInterestAmount] = useState(0);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 0);
  const [activehomeClass, setactivehomeClass] = useState("");
  const [activelockedClass, setactivelockedClass] = useState("");
  const [activehistoryClass, setactivehistoryClass] = useState("");

  const navigate = useNavigate();

  const [pageLoader, setpageLoader] = useState(false);
  useEffect(() => {
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
      getStakingHistory(1);
      getlockStakingHistory(1);

      getBalance();
    } else {
      setauthToken(false);
    }

    getStakeDetails(1);

    var get_tab = localStorage.getItem("activeTab");
    if(get_tab != null)
    {
      setActiveTab(get_tab);
      if(get_tab == "home-tab")
      {
        setactivehomeClass("active");
        setactivelockedClass("");
        setactivehistoryClass("")
      }
      else if(get_tab == "locked-tab")
      {
        setactivehomeClass("");
        setactivelockedClass("active");
        setactivehistoryClass("")
      }
      else if(get_tab == "history-tab")
      {
        setactivehomeClass("");
        setactivelockedClass("");
        setactivehistoryClass("active")
      }
    }
    else
    {
      setActiveTab("home-tab");
      setactivehomeClass("active");
      setactivelockedClass("");
      setactivehistoryClass("")
    }
  }, [0]);
  const [stakeHistory, setstakeHistory, stakeHistoryref] = useState([]);
  const [lockedStakes, setlockedStakes] = useState([]);

  const [stakecurrentpage, setstakecurrentpage] = useState(1);
  const [staketotalpage, setstakeTotalpages] = useState(0);

  const stakerecordpage = 5;
  const stakepagerange = 5;
  const handlepagestake = (event, page) => {
    event.preventDefault() 
    getStakeDetails(page);
    setstakecurrentpage(page);
  };

  const getStakeDetails = async (pages) => {
    var obj = {
      FilPerpage: 5,
      FilPage: pages,
      search: "",
    };
    setSiteLoader(true);
    var data = {
      apiUrl: apiService.getStatkingDetails,
      payload: obj,
    };
    setpageLoader(true);

    var resp = await postMethod(data);
    setpageLoader(false);
    setSiteLoader(false);

    if (resp.status == true) {
      var datas = resp.data.result;
      console.log(resp, "=-=-=-=-data");
      setstakeDetails(datas);
      setstakeTotalpages(resp.data.count);
    } else {
      setstakeDetails([]);
    }
  };

  const [historycurrentpage, sethistorycurrentpage] = useState(1);
  const [historytotalpage, sethistoryTotalpages] = useState(0);

  const historyrecordpage = 5;
  const historypagerange = 5;
  const handlepagehistory = (event, page) => {
    event.preventDefault() 
    getStakingHistory(page);
    sethistorycurrentpage(page);
  };

  const getStakingHistory = async (page) => {
    try {
      setSiteLoader(true);

      var data = {
        apiUrl: apiService.getAllstakingHistory,
        payload: { type: "Fixed", FilPerpage: 5, FilPage: page },
      };
      var resp = await postMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory(resp.data);
        sethistoryTotalpages(resp.count);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const [lockedcurrentpage, setlockedcurrentpage] = useState(1);
  const [lockedtotalpage, setlockedTotalpages] = useState(0);

  const lockedrecordpage = 5;
  const lockedpagerange = 5;
  const handlepagelocked = (event, page) => {
    event.preventDefault() 
    getlockStakingHistory(page);
    setlockedcurrentpage(page);
  };

  const getlockStakingHistory = async (page) => {
    try {
      setSiteLoader(true);

      var data = {
        apiUrl: apiService.getlockstakingHistory,
        payload: { type: "Fixed", FilPerpage: 5, FilPage: page },
      };
      var resp = await postMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setlockedStakes(resp.data);
        setlockedTotalpages(resp.count);

        console.log(lockedStakes, "lockedStakes");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const getBalance = async () => {
    try {
      setSiteLoader(true);

      var data = {
        apiUrl: apiService.getUserBalanceAll,
      };
      var resp = await getMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        setuserBalance(resp.data);
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const [historyLoader, sethistoryLoader] = useState(false);

  const handleAmountChange = (event) => {
    const inputAmount = parseFloat(event.target.value);
    console.log(inputAmount, "ihiikhiknik");
    setAmount(inputAmount);
    calculateInterestAmount(inputAmount, interestRate);
  };

  const calculateInterestAmount = (amount, interestRate) => {
    console.log(amount, "iknii", interestRate);
    const calculatedInterestAmount = amount * (interestRate / 100);
    setInterestAmount(calculatedInterestAmount);
  };

  const stakeNow = (currentPack) => {
    calculateEndDate("15days", currentPack);
    calculateInterest("15days", currentPack);

    try {
      setcurrentPack(currentPack);
      const index = userBalance.findIndex(
        (x) => x.currencySymbol === currentPack.currencySymbol
      );
      if (index !== -1) {
        const findCurrency = userBalance[index];
        setStakeBalance(findCurrency);
        console.log(findCurrency, "findCurrency");
      }
    } catch (error) {
      // Handle error
    }
  };

  const BreakNow = (currentPack) => {
    console.log(currentPack, "currentPack");
    try {
      setbreakpack(currentPack);
    } catch (error) {
      // Handle error
    }
  };

  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState();

  const [ButtonLoader, setButtonLoader] = useState(new Date());

  function calculateEndDate(duration, currentPack) {
    const currentDate = new Date();
    setStartDate(new Date());
    let endDate;

    switch (duration) {
      case "15days":
        endDate = new Date(currentDate.setDate(currentDate.getDate() + 15));
        break;
      case "1month":
        endDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        break;
      case "1.5months":
        const tempDate = new Date(
          currentDate.setMonth(currentDate.getMonth() + 1)
        );
        endDate = new Date(tempDate.setDate(tempDate.getDate() + 15));
        break;
      case "3months":
        endDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
        break;
      case "6months":
        endDate = new Date(currentDate.setMonth(currentDate.getMonth() + 6));
        break;
      case "1year":
        endDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() + 1)
        );
        break;
      default:
        endDate = new Date(currentDate.setDate(currentDate.getDate() + 15));
        break;
    }

    setEndDate(endDate);
    calculateInterest(duration, currentPack);
  }

  const handleDurationChange = (event, currentPack) => {
    const selectedDuration = event.target.value;
    setDuration(selectedDuration);
    calculateEndDate(selectedDuration, currentPack);
    calculateInterest(selectedDuration, currentPack);
  };

  const calculateInterest = (duration, currentPack) => {
    console.log(duration, currentPack, "data");

    let months;

    switch (duration) {
      case "15days":
        months = 0.5; // Half a month
        break;
      case "1month":
        months = 1;
        break;
      case "1.5months":
        months = 1.5;
        break;
      case "3months":
        months = 3;
        break;
      case "6months":
        months = 6;
        break;
      case "1year":
        months = 12;
        break;
      default:
        months = 0.5; // Default to 15 days
        break;
    }

    const calculatedInterest = months * (currentPack?.Interestrate || 0);
    console.log(months, currentPack?.Interestrate, calculatedInterest);
    setInterestRate(calculatedInterest);

    const calculatedInterestAmount = amount * (calculatedInterest / 100);
    setInterestAmount(calculatedInterestAmount);
  };

  const confirmStack = async () => {
    try {
      if (amount > 0) {
        if (
          amount >= currentPack.minimumStaking &&
          amount <= currentPack.maximumStaking
        ) {
          console.log(currentPack, "confirm staking");
          var obj = {
            stakingPlan: duration,
            totalInterest: interestAmount,
            startDate: startDate,
            endDate: endDate,
            currentAPY: currentPack.Interestrate,
            stakeMore: { id: currentPack._id },
            stakeAmont: amount,
            type: "fixed",
          };

          console.log(obj, "****stakingTypeyieldyieldstakingType*****8");
          setSiteLoader(true);

          var data = {
            apiUrl: apiService.confirmStaking,
            payload: obj,
          };

          setButtonLoader(true);
          var resp = await postMethod(data);
          getBalance();
          setSiteLoader(false);

          setButtonLoader(false);
          if (resp.status == true) {
            toast.success(resp.Message);
            setAmount("");
            window.location.reload();
          } else {
            setAmount("");
            window.location.reload();
            toast.error(resp.Message);
          }
        } else {
          toast.error("Enter the amount 0.01 to 1");
        }
      } else {
        toast.error("Enter stake amount");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const claimNow = async (claimData) => {
    console.log("0998098908908");
    try {
      var obj = {
        _id: claimData._id,
      };
      setSiteLoader(true);

      var data = {
        apiUrl: apiService.claimNowapi,
        payload: obj,
      };
      var resp = await postMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory(1);
        getlockStakingHistory(1);
        getBalance();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const Break = async (claimData) => {
    console.log("0998098908908", breakpack);

    try {
      var obj = {
        _id: breakpack._id,
      };
      var data = {
        apiUrl: apiService.breakstake,
        payload: obj,
      };
      setSiteLoader(true);

      var resp = await postMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        getlockStakingHistory(1);
        toast.success(resp.Message);
        getStakingHistory(1);
        getBalance();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const Movelogin = () => {
    navigate("/login");
  };

  const handleTabChange = (event) => {
    setActiveTab(event.currentTarget.getAttribute('data-id'));
    localStorage.setItem('activeTab', event.currentTarget.getAttribute('data-id')); // Update local storage
  };

  return (
    <main className="staking_page_main">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <section className="popular-cryptocurrencies-section staking_section">
          <div className="container">
            <div className="popular-cryptocurrencies staking_title">
              <h2> Staking</h2>
              {/* <p>
              <Link to="/" className="staking_home_link">
                <span>Home</span>
              </Link>
              <span className="stake_link_text">Staking</span>
            </p> */}

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  {activeTab == "home-tab" ? (
                  <button
                    className={`nav-link ${activehomeClass}`}
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    data-id="home-tab"
                    onClick={handleTabChange}
                  >
                    Staking{" "}
                  </button>
                  ) : (
                    <button
                    className={`nav-link ${activehomeClass}`}
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    data-id="home-tab"
                    onClick={handleTabChange}
                  >
                    Staking{" "}
                  </button>
                  )}
                </li>
                <li class="nav-item" role="presentation">
                {activeTab == "locked-tab" ? (
                  <button
                  className={`nav-link ${activelockedClass}`}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    data-id="locked-tab"
                    onClick={handleTabChange}
                  >
                    Locked Staking
                  </button>
                ) : (

                <button
                className={`nav-link ${activelockedClass}`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  data-id="locked-tab"
                  onClick={handleTabChange}
                >
                  Locked Staking
                </button>

                )}
                </li>
                <li class="nav-item" role="presentation">
                {activeTab == "history-tab" ? (
                  <button
                  className={`nav-link ${activehistoryClass}`}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#yield "
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    data-id="history-tab"
                    onClick={handleTabChange}
                  >
                    Staking History
                  </button>
                ) : (
                  <button
                  className={`nav-link ${activehistoryClass}`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#yield "
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  data-id="history-tab"
                  onClick={handleTabChange}
                >
                  Staking History
                </button>
                )}
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="table-responsive deposite_tabel_border">
                    <table className="table staking_tabel">
                      <thead>
                        <tr className="tabel_bottom_border">
                          <th scope="col" className="starts">
                            Sr No
                          </th>
                          <th scope="col">Assets</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Return %</th>
                          <th scope="col" className="ends">
                            Action
                          </th>
                        </tr>
                        <div className="kyc_border"></div>
                      </thead>
                      <tbody>
                        {stakeDetails.length > 0
                          ? stakeDetails.map((item, i) => {
                            const balance = userBalance.find(
                              (balanceItem) =>
                                balanceItem.currencySymbol ===
                                item.currencySymbol
                            );

                            return (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>
                                  {" "}
                                  <div className="name-coin-item">
                                    <img
                                      width="35px"
                                      src={item.currencyImage}
                                    />
                                    <p>{item.currencySymbol}</p>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  {balance
                                    ? balance.currencyBalance.toFixed(4)
                                    : 0}{" "}
                                  {item.currencySymbol}
                                </td>
                                <td>{item.Interestrate} % /Month</td>
                                <td>

                                  {
                                    authToken == true ? (
                                      <button
                                        className="staking_stake_btn"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal_1"
                                        onClick={() => stakeNow(item)}
                                      >
                                        Stake
                                      </button>
                                    ) : (
                                      <button
                                        className="staking_stake_btn"

                                        onClick={Movelogin()}
                                      >
                                        Login
                                      </button>
                                    )
                                  }


                                </td>
                              </tr>
                            );
                          })
                          : <td colSpan={5}>
                            <div className="empty_data">
                              <div className="empty_data_img">
                                <img
                                  src={require("../images/No-data.png")}
                                />
                              </div>

                              <div className="no_records_text">
                                No Records Found
                              </div>
                            </div>
                          </td>}
                      </tbody>
                    </table>

                    {stakeDetails && stakeDetails.length > 0 ? (
                      <div className="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(staketotalpage / stakerecordpage)}
                            page={stakecurrentpage}
                            onChange={handlepagestake}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="table-responsive deposite_tabel_border">
                    <table className="table staking_tabel">
                      <thead>
                        <tr className="tabel_bottom_border">
                          <th scope="col" className="starts">
                            Currency
                          </th>
                          <th scope="col">Stake Amount</th>
                          <th scope="col">Reward Rate</th>
                          <th scope="col">Duration </th>
                          <th scope="col">Stake Start Date</th>
                          <th scope="col"> Stake End Date</th>
                          <th scope="col"> Interest </th>
                          <th scope="col"> Receive Amount </th>

                          <th scope="col" className="ends">
                            Action
                          </th>
                        </tr>
                        <div className="kyc_border"></div>
                      </thead>
                      <tbody>
                        {lockedStakes.length > 0
                          ? lockedStakes.map((item, i) => {
                            return (
                              <tr>
                                <td>
                                  {" "}
                                  <div className="name-coin-item">
                                    <img
                                      width="25px"
                                      src={item.currencyImage}
                                    />
                                    <p>{item.stakeCurrencsymbol}</p>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  {item.stakeAmont} {item.stakeCurrencsymbol}
                                </td>
                                <td>{item.currentAPY} % /Month</td>
                                <td>{item.stakingPlan}</td>
                                <td>
                                  {Moment(item.startDate).format("lll")}
                                </td>

                                <td>{Moment(item.endDate).format("lll")}</td>
                                <td>
                                  {parseFloat(item.totalInterest).toFixed(4)}{" "}
                                  {item.stakeCurrencsymbol}
                                </td>

                                <td>
                                  {(
                                    item.totalInterest + item.stakeAmont
                                  ).toFixed(4)}{" "}
                                  {item.stakeCurrencsymbol}
                                </td>

                                <td>

                                  {authToken == true ? (
                                    <button
                                      className="staking_stake_btn"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal_2"
                                      onClick={() => BreakNow(item)}
                                    >
                                      Break
                                    </button>
                                  ) : (
                                    <button
                                      className="staking_stake_btn"

                                      onClick={Movelogin(item)}
                                    >
                                      Login
                                    </button>
                                  )}

                                </td>
                              </tr>
                            );
                          })
                          : <td colSpan={9}>
                            <div className="empty_data">
                              <div className="empty_data_img">
                                <img
                                  src={require("../images/No-data.png")}
                                />
                              </div>

                              <div className="no_records_text">
                                No Records Found
                              </div>
                            </div>
                          </td>}
                      </tbody>
                    </table>
                    {lockedStakes && lockedStakes.length > 0 ? (
                      <div className="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(
                              lockedtotalpage / lockedrecordpage
                            )}
                            page={lockedcurrentpage}
                            onChange={handlepagelocked}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                            type="button"
                          />
                        </Stack>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="yield"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="table-responsive deposite_tabel_border">
                    <table className="table staking_tabel">
                      <thead>
                        <tr className="tabel_bottom_border">
                          <th scope="col" className="starts">
                            Currency
                          </th>
                          <th scope="col">Stake Amount</th>
                          <th scope="col">Reward Rate</th>
                          <th scope="col">Duration </th>
                          <th scope="col">Stake Start Date</th>
                          <th scope="col"> Stake End Date</th>
                          <th scope="col"> Interest </th>
                          <th scope="col"> Receive Amount </th>

                          <th scope="col" className="ends">
                            Action
                          </th>
                        </tr>
                        <div className="kyc_border"></div>
                      </thead>
                      <tbody>
                        {stakeHistoryref.current.length > 0
                          ? stakeHistoryref.current.map((item, i) => {
                            return (
                              <tr>
                                <td>
                                  {" "}
                                  <div className="name-coin-item">
                                    <img
                                      width="25px"
                                      src={item.currencyImage}
                                    />
                                    <p>{item.stakeCurrencsymbol}</p>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  {item.stakeAmont} {item.stakeCurrencsymbol}
                                </td>
                                <td>{item.currentAPY} % /Month</td>
                                <td>{item.stakingPlan}</td>
                                <td>
                                  {Moment(item.startDate).format("lll")}
                                </td>

                                <td>{Moment(item.endDate).format("lll")}</td>
                                <td>
                                  {parseFloat(item.totalInterest).toFixed(4)}{" "}
                                  {item.stakeCurrencsymbol}
                                </td>

                                <td>
                                  {item.status === 3
                                    ? `${item.stakeAmont.toFixed(4)} ${item.stakeCurrencsymbol
                                    }`
                                    : `${(
                                      item.totalInterest + item.stakeAmont
                                    ).toFixed(4)} ${item.stakeCurrencsymbol
                                    }`}
                                </td>

                                <td>
                                  {item.status == 1 ? (
                                    <button
                                      className="active staking_stake_btn"
                                      onClick={() => claimNow(item)}
                                    >
                                      Claim Now
                                    </button>
                                  ) : item.status == 3 ? (
                                    <button className="notactive staking_stake_btns">
                                      Breaked
                                    </button>
                                  ) : item.status == 0 ? (
                                    <button className="notactive staking_stake_btns">
                                      {" "}
                                      Claim
                                    </button>
                                  ) : (
                                    <button className="notactive staking_stake_btns">
                                      {" "}
                                      Claimed
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                          : <td colSpan={5}>
                            <div className="empty_data">
                              <div className="empty_data_img">
                                <img
                                  src={require("../images/No-data.png")}
                                />
                              </div>

                              <div className="no_records_text">
                                No Records Found
                              </div>
                            </div>
                          </td>}
                      </tbody>
                    </table>
                    {stakeHistoryref.current &&
                      stakeHistoryref.current.length > 0 ? (
                      <div className="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(
                              historytotalpage / historyrecordpage
                            )}
                            page={historycurrentpage}
                            onChange={handlepagehistory}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <section className="smart-footer">
              <Footer />
            </section>
          </div>

          {/* Staking PopUp */}
          <div
            className="modal fade"
            id="exampleModal_1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content staking_modal_content">
                <div className="modal-head">
                  <div className="sign-up-popup-content">
                    <div className="sign_up_title">
                      <h3 className="stake_popup_title">Stake</h3>
                      <button
                        type="button"
                        className="btn-close staking_close_button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="stake_modal_balance">
                    <p>Balance</p>
                    <h3>
                      {" "}
                      {stakeBalance ? stakeBalance.currencyBalance : 0}{" "}
                      {currentPack.currencySymbol}
                    </h3>
                  </div>
                  <div className="gc_hr"></div>
                  <div className="stake_modal_balance">
                    <p>Interest Amount</p>
                    <h3>{currentPack.Interestrate} % /Month</h3>
                  </div>
                  <div className="st_body">
                    <div className="enter_stake_amt">
                      <h3>Amount</h3>

                      <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder="Enter Amount"
                      />
                      <span className="txt_badge ">
                        <small>
                          Min: {currentPack.minimumStaking}{" "}
                          {currentPack.currencySymbol}
                        </small>
                        <small>
                          Max: {currentPack.maximumStaking}{" "}
                          {currentPack.currencySymbol}
                        </small>
                      </span>
                    </div>
                    <div class="form-group mb-3 mt-3">
                      <label for="phase" className="enter_stake_amt">
                        <h3>Staking Days</h3>{" "}
                      </label>

                      <select
                        name="Days"
                        className="staing_days_dropdown"
                        value={duration}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        min="0"
                        minAmount="0"
                        onChange={(e) => handleDurationChange(e, currentPack)}
                      >
                        <optgroup>
                          <option value="15days">15 Days</option>
                          <option value="1month">1 Month</option>
                          <option value="1.5months">1.5 Months</option>
                          <option value="3months">3 Months</option>
                          <option value="6months">6 Months</option>
                          <option value="1year">1 Year</option>
                        </optgroup>
                      </select>
                    </div>
                    <button
                      className="staking_btn_stake"
                      onClick={confirmStack}
                    >
                      Stake
                    </button>
                    <div className="minimum_staking_amount mt-3">
                      <p>Minimum staking amount</p>
                      <p>
                        {currentPack.minimumStaking}{" "}
                        {currentPack.currencySymbol}
                      </p>
                    </div>
                    <div className="minimum_staking_amount">
                      <p>Maximum staking amount</p>
                      <p>
                        {currentPack.maximumStaking}{" "}
                        {currentPack.currencySymbol}
                      </p>
                    </div>
                    <div className="minimum_staking_amount">
                      <p>You will receive</p>
                      <p>{interestAmount + amount}</p>
                    </div>
                    <div className="minimum_staking_amount">
                      <p>Transaction Cost</p>
                      <p>NA</p>
                    </div>
                    <div className="summary_text">Summary</div>
                    <div className="minimum_staking_amount mt-3">
                      <p>Total estimate reward</p>
                      <p>
                        {interestAmount} {currentPack.currencySymbol}
                      </p>
                    </div>
                    <div className="minimum_staking_amount">
                      <p>Reward Percentage</p>
                      <p>{interestRate} %</p>
                    </div>
                    <div className="minimum_staking_amount">
                      <p>Interest end date</p>
                      <p>{Moment(endDate).format("lll")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal_2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content staking_modal_content">
                <div className="modal-head">
                  <div className="sign-up-popup-content">
                    <div className="sign_up_title">
                      <h3 className="stake_popup_title">Break - Stake</h3>
                      <button
                        type="button"
                        className="btn-close staking_close_button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  {/* <div className="stake_modal_balance">
                  <p>Balance</p>
                  <h3>
                    {" "}
                    {stakeBalance ? stakeBalance.currencyBalance : 0}{" "}
                    {currentPack.currencySymbol}
                  </h3>
                </div> */}
                  <div className="gc_hr"></div>
                  <div className="stake_modal_balance">
                    <p>Interest Amount</p>
                    <h3>{breakpack.currentAPY} % /Month</h3>
                  </div>
                  <div className="st_body">
                    <div className="minimum_staking_amount">
                      <p>You will receive</p>
                      <p>{breakpack.stakeAmont}</p>
                    </div>

                    <div className="minimum_staking_amount">
                      <p>Interest end date</p>
                      <p>{Moment(currentPack.endDate).format("lll")}</p>
                    </div>

                    <button
                      className="staking_btn_stake"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={Break}
                    >
                      Break
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default Staking;
