import React, { useState, useEffect } from "react";

import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Moment from "moment";
// import { socket } from "./context/socket";
import Home_header from "./Home_header";
import Footer from "./Footer";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Bars } from "react-loader-spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import io from "socket.io-client";
const Dashboard = () => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    const socket = io("http://localhost:3033"); // Replace with your server URL
    socket.on("connect", () => {
      console.log("Connected to server");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const eventData = "Hello";

  // socket.emit('eventName', eventData);

  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch, searchref] = useStateRef("");
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [refreshStatus, setrefreshStatus] = useState(false);

  const [balanceDatas, setbalanceDatas] = useState([]);

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, "");
      setsearch(sanitizedValue);
      searchWalletList();
    } catch (error) { }
  };

  const recordPerPage = 5;
  const totalRecords = 7;
  const pageRange = 5;
  const navigate = useNavigate();

  const depositNav = () => {
    navigate("/deposit");
  };
  const navigatereferal = () => {
    navigate("/referral");
  };
  const buycryptoNav = () => {
    navigate("/p2p");
  };
  const withdrawNav = () => {
    navigate("/Withdraw");
  };

  const handlePageChange = (event, value) => {
    console.log(value, "ujbjjnjn");

    setCurrentPage(value);
    var current_page = +value * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      resp_balance.push(balanceDatas[i]);
    }
    setbalanceDetails(resp_balance);
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);

      if (resp.status == true) {
        setprofileData(resp.data);
        // console.log(resp.data);
        setLastLogin(resp.lastLogin);

        const timestamp = resp.lastLogin.createdDate;
        const date = new Date(timestamp);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const timeZoneCountry = Intl.DateTimeFormat(undefined, {timeZoneName: 'long'}).formatToParts(date)
        //   .find(part => part.type === 'timeZoneName').value;
        setTimeZone(timeZone);

        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) { }
  };

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    if (resp.status == true) {
      var balanceData = resp.balance;
      setSiteLoader(false);
      console.log(balanceData);
      console.log(balanceData.total_balance);
      console.log(balanceData.available_balance);
      console.log(balanceData.inorder_balance);
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    }
  };

  const getUserbalance = async (pages) => {
    setSiteLoader(false);

    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      setSiteLoader(false);
      console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        resp_balance.push(balanceData[i]);
      }
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      console.log(resp.total, "resp.totalresp.total");
      var balanceData = resp.balance;
    } else {
    }
  };

  const searchWalletList = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = balanceDatas.filter((data) =>
        regexPattern.test(data.currencysymbol)
      );
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
      if (searchWallet.length > 0) {
        setbalanceDetails(searchWallet);
        setDataHide(false);
        settotal(1);
      } else {
        getUserbalance(1);
      }
    } else {
      getUserbalance(1);
    }
  };

  const searchWalletListTwo = async () => {
    const regexPattern = new RegExp(searchref.current, "i");
    console.log(balanceDatas);
    var data = [];
    for (let index = 0; index < balanceDatas.length; index++) {
      const element = balanceDatas[index];
      if (element.currencyBalance > 0) {
        console.log(element);
        data.push(element);
      }
      setbalanceDetails(data);
    }
    setDataHide(true);
    settotal(1);
  };

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      // console.log(resp, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    // socket.on("sitesettings", function (res) {
    //   console.log(res);
    // });
    getProfile();
    FindData();
    Kycdata();
    getUserbalance(currentPage);
    getUserTotalbalance(currentPage);
    refreshUse();
    console.log(profileData, "-=profileData=-");
  }, [0]);


  const refresh = () => {
    setrefreshStatus(true);
    refreshUse();
  }


  const refreshUse = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    var resp = await getMethod(data);
    if (resp.message == true) {
      getUserbalance(1);
      setrefreshStatus(false);
    }
  };

  const [textToCopy, setTextToCopy] = useState("");
  const [copied, setCopied] = useState(false);
  const [changeCode, setchangeCode] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
    };
    var responce = await postMethod(data);
    if (responce.data != null) {
      if (responce.data.APcode != "") {
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    } else {
      setchangeCode(false);
    }
  };

  const Kycdata = async () => {
    var data = {
      apiUrl: apiService.getKYC,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      if (resp.status != "") {
        var kycData = resp.datas.kycDetails;
        console.log(kycData, "kycData");
        setgetKYCData(kycData);
      }
    }
  };

  const copyText = () => {
    const profileId = profileData.uuid;
    navigator.clipboard
      .writeText(profileId)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
        toast.success("Copied Successfully !");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const [loader, setloader] = useState(false);



  const [dataHide, setDataHide] = useState(false);

  const datasHide = (data) => {
    if (data == false) {
      setDataHide(true);
      searchWalletListTwo();
    } else {
      setDataHide(false);
      searchWalletList();
    }
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState(false);

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType(true);
    } else {
      setPasshide(false);
      setinputType(false);
    }
  };

  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <section className="dashboard_content_section ">
            <div className="container">
              <div className="smart_dashboard_content">
                <h2>Dashboard</h2>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="dash_board_profile_content">
                      <div className="dash_profile_img">
                        <img
                          src={require("../images/dashboard/profile_img.png")}
                        />
                        <p>{profileData.username}</p>
                      </div>
                      <div className="dash_board_profile_content_text">
                        <div className="col-lg-2">
                          <div className="udi-text">
                            <h4>UID</h4>
                            <p>
                              {profileData.uuid}{" "}
                              <i
                                class="ri-file-copy-fill"
                                onClick={copyText}
                              ></i>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="id-text">
                            <h4>Identity Verification</h4>
                            <p>

                              {getKYCData == null
                                ? <>
                                  <i class="fa-regular fa-circle-xmark text-danger mr-2"></i><span className="text-danger">Not Uploaded</span>
                                </>
                                : getKYCData.kycStatus == 3
                                  ? <>
                                    <i class="fa-regular fa-circle-xmark text-danger mr-2"></i><span className="text-danger">Rejected</span>
                                  </>
                                  : getKYCData.kycStatus == 2
                                    ? <>
                                      <i class="fa-solid fa-clock-rotate-left text-warning mr-2"></i><span className="text-warning">Pending</span>
                                    </>
                                    : getKYCData.kycStatus == 1
                                      ? <>
                                        <i class="fa-regular fa-circle-check text-success mr-2"></i><span className="text-success">Verified</span>
                                      </>
                                      : <><i class="fa-regular fa-circle-xmark text-danger mr-2"></i><span className="text-danger">Not Uploaded</span></>}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="id-text">
                            <h4>Security</h4>
                            <p>
                              {/* <i class="ri-error-warning-line"></i> */}
                              {profileData.mobileNumber == "" &&
                                localStorage.getItem("tfa_status") == 0 &&
                                changeCode == false
                                ? <><i class="ri-error-warning-line text-danger mr-2"></i><span className="text-danger">Low</span></>
                                : localStorage.getItem("tfa_status") == 0 ||
                                  changeCode == false
                                  ? <>
                                    <i class="ri-error-warning-line text-warning mr-2"></i><span className="text-warning">Medium</span>
                                  </>
                                  : <>
                                    <i class="fa-regular fa-circle-check text-success mr-2"></i><span className="text-success">High</span>
                                  </>}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="time-zone-text">
                            <h4>Time Zone</h4>
                            <p>{timeZone}</p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {" "}
                          <div className="time-zone-text">
                            <h4>Last Login</h4>
                            <p>
                              {" "}
                              {Moment(lastLogin.createdDate).format("lll")} ( IP
                              : {lastLogin.ipAddress} )
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dashborad_deposit_wuthdraw_card">
                      <h3>
                        {" "}
                        {AvailablePrice == "" ||
                          AvailablePrice == null ||
                          AvailablePrice == undefined
                          ? 0.0
                          : AvailablePrice.toFixed(4)}{" "}
                        USD
                      </h3>
                      <div>
                        <button
                          onClick={depositNav}
                        >
                          Deposit
                        </button>
                        <button
                          className="withdraw_button"
                          onClick={withdrawNav}
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>
            <button className="button" onClick={refresh}>
              {refreshStatus == true ?
                <i class="fa-solid fa-spinner fa-spin"></i>
                :
                <i class="fa fa-spinner"></i>
              } {"    "}
              Refresh
            </button>
          </div>
          <section className="dashboard_history_tabel_section">
            <div className="container">
              <div className="table-responsive deposite_tabel_border">
                <table className="table">
                  <thead>
                    <tr className="tabel_bg">
                      <th scope="col" className="starts">
                        Name
                      </th>
                      <th scope="col">Locked Balance</th>
                      <th scope="col">Available Balance</th>
                      <th scope="col">Total Balance</th>
                      <th scope="col" className="action_text ends">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {balanceDetails && balanceDetails.length > 0 ? (
                      balanceDetails.map((item, i) => {
                        if (!item) {
                          console.error(`Item at index ${i} is undefined.`);
                          return null; // Skip rendering this item
                        }
                        return (
                          <tr className="history_tabel_bottom_border" key={i}>
                            <th scope="row">
                              <div className="tabel_bitcoin_name">
                                <img src={item.currencyImage} />
                                <div className="tabel_bitcoin_name_content">
                                  <h4>{item.currencysymbol}</h4>
                                  <p>{item.currencyName}</p>{" "}
                                </div>
                              </div>
                            </th>
                            <td className="tabel_data_usdt">
                              {inputType == false ? (
                                <>
                                  <p>
                                    {parseFloat(item.holdAmount).toFixed(4)}{" "}
                                    {item.currencysymbol}
                                  </p>
                                  <p className="tabel_data_usdt_content">
                                    {" "}
                                    {parseFloat(item.estimatedUSDThold).toFixed(
                                      4
                                    )}
                                    $
                                  </p>
                                </>
                              ) : (
                                <p>********</p>
                              )}
                            </td>
                            <td className="tabel_data_usdt">
                              {inputType == false ? (
                                <>
                                  <p>
                                    {parseFloat(item.currencyBalance).toFixed(
                                      4
                                    )}{" "}
                                    {item.currencysymbol}
                                    <br />
                                  </p>
                                  <p className="tabel_data_usdt_content">
                                    {parseFloat(
                                      item.estimatedUSDTbalance
                                    ).toFixed(4)}
                                    $
                                  </p>
                                </>
                              ) : (
                                <p>******</p>
                              )}
                            </td>
                            <td className="tabel_data_usdt">
                              {inputType == false ? (
                                <>
                                  <p>
                                    {parseFloat(
                                      item.currencyBalance +
                                      parseFloat(item.holdAmount)
                                    ).toFixed(4)}{" "}
                                    {item.currencysymbol} <br />
                                  </p>
                                  <p className="tabel_data_usdt_content">
                                    {" "}
                                    {parseFloat(
                                      item.estimatedUSDTtotal
                                    ).toFixed(4)}{" "}
                                    $
                                  </p>
                                </>
                              ) : (
                                <p>******</p>
                              )}
                            </td>
                            <td className="tabel_data_usdt_buttons">
                              <button>
                                {" "}
                                <Link to="/deposit">Deposit</Link>{" "}
                              </button>
                              <button>
                                {" "}
                                <Link to="/withdraw">Withdraw</Link>{" "}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">
                          <span className="w-100 text-center text-white d-block">
                            No Records found!
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {balanceDetails && balanceDetails.length > 0 ? (
                  <div className="pagination">
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(total / recordPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </>
      )}

      <Footer />
    </main>
  );
};

export default Dashboard;
